@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
}

#root {
  margin: 0px;
  padding: 0px;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.transition-fade-exit {
  opacity: 1;
}

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.svg-icon svg {
  position: relative;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
}

.svg-text svg {
  stroke: #424242;
}

.svg-180 svg {
  transform: rotate(180deg);
}

.form-input {
  padding: 0.375rem;
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #424242;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 1px 2px #8ecae6;
}

.is-fullwidth {
  width: 100%;
}

.bg-white {
  background-color: white;
}

.data-input {
  white-space: nowrap;
  overflow: hidden; 
  border: none;
  padding: 0.3rem;
  color: #000000;
  font-size: 1rem;
  border-radius: 4px;
  resize: none;
  background-color: white;
  box-sizing: border-box;
  flex: 1 1 auto;
  font-weight: bold;
}

.data-input:focus {
  outline: none;
}

.shadow-5 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.12),
    0 8px 16px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
  position: relative;
  height: 1.25em;
  width: 1.25em;
}

.svg-gray svg {
  stroke: #9e9e9e;
}

.option-input {
  width: 100%;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.option-input:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 1000vh;
  width: 1000vw;
  z-index: 10;
  overflow: hidden;
}

.context-menu:hover {
  background-color: rgb(201, 201, 201); 
}

.sort-button {
  padding: 0.25rem 0.75rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  color: #757575;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.sort-button:hover {
  background-color: #eeeeee;
}

.add-row {
  color: #9e9e9e;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.add-row:hover {
  background-color: #f5f5f5;
}

.th.first-column {
  position: sticky;
  left: 0;
  z-index: 1;
}

.tr:last-child .td {
  border-bottom: 0;
}

.tr-header {
  z-index: 2;
}

.tr-header:last-child .td {
  border-bottom: 0;
}

.td:first-child{
  position: sticky;
  left: 0;
  z-index: 0;
}

.th {
  white-space: nowrap;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  max-width: 55px;
}

.th:first-child {
  max-width: 120px;
  left: 0;
  position: sticky;
  z-index: 4;
}

.custom-class {
  max-width: none !important;
}

.td {
  white-space: nowrap;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  max-width: 55px;
}

.td:first-child {
  max-width: 120px;
}

.td2 {
  white-space: nowrap;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.th {
  color: #9e9e9e;
  font-weight: 700;
  font-size: 0.8rem;
  cursor: auto;
  position: sticky;
  top: 0;  
  z-index: 1;
}

.th:hover {
  background-color: #f5f5f5;
}

.th-h {
  z-index: 0;
  display: inline-block;
}
.th-h:first-child {
  position: sticky;
  left: 0;  
  right: 0;
  z-index: 4;
}

.th-content {
  overflow: hidden;
  padding: 0.2rem;
  padding-left: 0.5rem;
  height: 45px;
  align-items: center;
  position: relative;
  top: 0;  
}

.thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.thead:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.td {
  overflow: hidden;
  color: #424242;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 35px;
}

.td-content {
  display: block;
  align-items: center;
}

.td2 {
  overflow: hidden;
  color: #424242;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 35px;
}


.table {
  border-spacing: 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  z-index: 0;
}

td:first-child {
  position: sticky;
  left: 0;
}

th:first-child {
  position: sticky;
  left: 0;
}

.th:last-child {
  border-right: 0;
}

.td:last-child {
  border-right: 0;
}

.td2:last-child {
  border-right: 0;
}


.sync-scroll {
  position: abusolute;
}

.table-container {
  position: abusolute;
  height: 500px;
}

.table-container-r {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  flex: 1;
}

.body-container-r-img {
  height: 100%;
  display: flex;
  width: 1350px;
}
.table-container-m-img {
  position: relative;
  height: 100%;
  width: 2010px;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: white;
}

.table-container-r1 {
  position: abusolute;
  height: 103vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*スタッフ情報、前月、スタッフ集計の表*/
.table-container-sub {
  overflow-x: scroll;
  min-width: 100%;
  max-width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-sub::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-sub::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.table-container-sub-skill {
  overflow-x: scroll;
  min-width: 100%;
  max-width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-sub-skill::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-sub-skill::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.table-container-sub-day {
  overflow-x: scroll;
  min-width: 100%;
  max-width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-sub-day::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-sub-day::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/*勤務表*/
.table-container-m {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.table-container-shift-f {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-shift-f::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-shift-f::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.table-container-shift-skill-f {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-shift-skill-f::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-shift-skill-f::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.table-container-shift-day-f {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: thin;
  margin-bottom: -2vh;
}
.table-container-shift-day-f::-webkit-scrollbar {
  width: 0rem; 
}
.table-container-shift-day-f::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scroll {
  scrollbar-width: thin;
  margin-bottom: -1.2rem;
}
.hide-scroll::-webkit-scrollbar {
  width: 0rem; 
}
.hide-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scrollbar {
  scrollbar-width: thin;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0rem; 
}
.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.table-container-skill {
  overflow-x: scroll;
  width: 100%;
  height: 130px;
}
.table-container-day {
  overflow-x: scroll;
  width: 100%;
  height: 168px;
}

.body-container {
  height: 500px;
  display: flex;
}

.body-container-r {
  height: 100%;
  display: flex;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
}

.resizer:hover {
  background-color: #8ecae6;
}

.text-align-right {
  text-align: right;
}

.cell-padding {
  padding: 0.2rem;
}

.d-flex {
  display: flex;
}

.cursor-default {
  cursor: default;
}

.align-items-center {
  align-items: center;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.border-radius-md {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-margin {
  margin-right: 4px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-75 {
  font-size: 0.75rem;
}

.flex-1 {
  flex: 1;
}

.file-upload {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
  line-height: 1.333em;
}
.file-upload label {
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  font-family: arial, sans-serif;
}
.file-upload input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: regular;
  box-sizing: border-box;
  cursor: pointer;
}
.file-upload input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  margin-left: 10px;
  padding: 0;
  height: 40px;
  width: 0;
}
.file-upload button {
  position: absolute;
  right: 0%;
  bottom: 0;
  width: 40px;
  height: 40px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #1a8844;
  border-radius: 0 3px 3px 0;
  font-size: 1rem;
  font-weight: 800;
  box-sizing: border-box;
  border: 0px;
}

.download-excel-button {
  border: none;
  background: #10ad4c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 6px 18px 6px 18px;
  border-radius: 3px;
  cursor: pointer;
  right: 0;
  left: 100%;
  width: 110px;
}
.download-excel-button img {
  width: 25px;
  margin-left: 5px;
}

.excel-table-container {
  margin: 5px 0px;
}

.foo {
  display: flex;
  justify-content: space-between;
}

.text-deco {
  text-decoration: none;
  color: #424242;
}

.text-decos {
  text-decoration: none;
  color: #ffffff;
}

.save-button {
  width: 40px;
  height: 20px;
  color: white;
  background-color: #10ad4c;
  border-radius: 0 3px 3px 0;
  font-size: 1rem;
  font-weight: 800;
  box-sizing: border-box;
  border: 0px;
}


.text-box {
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  height: 6em;
  padding: 10px;
  border: 1px solid #999;
  box-sizing: border-box;
  background: #ffffff;
  margin: 0.5em 0;
}

.message-button {
  position: relative;
  height: 40px;
  width: 50px;
  padding-top: 10px;  
}


.progress-bar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
}
.progress-bar li {
  flex: 2;
  position: relative;
  padding: 0 0 14px 0;
  font-size: var(--font-size-default);
  line-height: 0.5;
  color: var(--color-green);
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  bottom: 12px;
  border-bottom: 2px solid var(--color-gray-disabled);
}
.progress-bar li:first-child,
.progress-bar li:last-child {
  flex: 1;
}
.progress-bar li:last-child {
  text-align: right;
}
.progress-bar li:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--color-gray-disabled);
  border-radius: 50%;
  border: 2px solid var(--color-white);
  position: absolute;
  left: calc(50% - 7px);
  bottom: -6px;
  z-index: 3;
  transition: all .2s ease-in-out;
}
.progress-bar li:first-child:before {
  left: 0;
}
.progress-bar li:last-child:before {
  right: 0;
  left: auto;
}
.progress-bar span {
  transition: opacity .3s ease-in-out;
}
.progress-bar li:not(.is-active) span {
  opacity: 0;
}
.progress-bar .is-complete:not(:first-child):after,
.progress-bar .is-active:not(:first-child):after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: -50%;
  z-index: 2;
  border-bottom: 2px solid var(--color-green);
}
.progress-bar li:last-child span {
  width: 200%;
  display: inline-block;
  position: absolute;
  left: -100%;
}
 
.progress-bar .is-complete:last-child:after,
.progress-bar .is-active:last-child:after {
  width: 200%;
  left: -100%;
}
 
.progress-bar .is-complete:before {
  background-color: var(--color-green);
}
 
.progress-bar .is-active:before,
.progress-bar li:hover:before,
.progress-bar .is-hovered:before {
  background-color: var(--color-white);
  border-color: var(--color-green);
}
.progress-bar li:hover:before,
.progress-bar .is-hovered:before {
  transform: scale(1.33);
}
 
.progress-bar li:hover span,
.progress-bar li.is-hovered span {
  opacity: 1;
}
 
.progress-bar:hover li:not(:hover) span {
  opacity: 0;
}

:root {
  --color-white: #fff;
  --color-black: #333;
  --color-gray: #75787b;
  --color-gray-light: #bbb;
  --color-gray-disabled: #e8e8e8;
  --color-green: #53a318;
  --color-green-dark: #383;
  --font-size-small: .70rem;
  --font-size-default: .805rem;
}




.cover {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3)
}
.form {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 30%;
  right: 30%;
  left: 25%;
  font-weight: bold;
  background: rgb(228, 252, 254);
  width: 300px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.inView {
  opacity: 1;
  visibility: visible
}

.colors{
  background-color: #0062ca;
}

.toggle-button {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid gray;
}

.slider {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
  border: 1px solid gray;
}

.on {
  background-color: #4CAF50; /* オンのスタイル */
}

.on .slider {
  transform: translateX(20px);
}

.off {
  background-color: #ccc; /* オフのスタイル */
}


.styled-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
}

.styled-table th, .styled-table td {
  border: 1px solid #000000;
  padding: 4px;
  text-align: left;
}

.styled-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
}

.styled-table thead th {
  z-index: 2;
}

.styled-table button {
  width: 100%;
  padding: 4px;
  text-align: center;
  cursor: pointer;
}

.styled-table button:hover {
  background-color: #ddd;
}

.custom-list {
  list-style-type: none; 
}

.styled-table2 {
  border-collapse: collapse;
  position: relative;
}

.styled-table2 th, .styled-table2 td {
  border: 1px solid gray;
  padding-left: 0.5rem;
  text-align: left;
}

.styled-table2 td:not(:first-child) {
  text-align: right;
  padding-right: 0.5rem;
  min-width: 70px;
  max-width: 70px;
}

.styled-table2 th:not(:first-child) {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 14px; 
  min-width: 70px;
  max-width: 70px;
  text-align: left;
}

.styled-table2 th:first-child {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  min-width: 150px; 
  max-width: 150px; 
  font-size: 14px; 
}

.styled-table2 td:first-child {
  background-color: hsl(120, 100%, 96%);
  position: sticky;
  left: 0;
  z-index: 1;
  min-width: 150px; 
  max-width: 150px; 
  font-size: 14px; 
  cursor: pointer;
}

.styled-table2 thead th {
  z-index: 2;
}

.styled-table2 button {
  width: 100%;
  padding: 4px;
  text-align: center;
  cursor: pointer;
}

.styled-table2 button:hover {
  background-color: #ddd;
}

.styled-table2 .active-cell {
  background-color: rgb(155, 230, 148);
}

.styled-table2 .active-row {
  background-color: rgb(218, 227, 243);
}

.styled-table2 td:first-child.active-row  {
  background-color: rgb(218, 227, 243);
}

.styled-table2 td:first-child.active-cell  {
  background-color: rgb(155, 230, 148);
}

.styled-table2 .active-column {
  background-color: rgb(218, 227, 243);
}


.active-tab {
  cursor: pointer;
  background-color: lightblue;
  border: 0.5px solid gray; 
  padding-left: 1px; 
  padding-right: 1px; 
}

.inactive-tab {
  cursor: pointer;
  border: 0.5px solid lightgray; 
  padding-left: 1px; 
  padding-right: 1px; 
}
